<template>
    <span>
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px">
            <v-card-title primary-title class="justify-center">
                <h2>Bienvenue</h2>
            </v-card-title>
            <v-card-text>
                <p>Découvrez Smile Survey : Votre solution complète pour les formulaires et les enquêtes de satisfaction !</p>
                <p>Smile Survey est une application innovante conçue pour simplifier la création de formulaires et d'enquêtes de satisfaction. Avec Smile Survey, la création de formulaires devient un jeu d'enfant. Créez votre propre formulaire personnalisé en quelques clics.</p>
            </v-card-text>
            <v-card-text class="py-10">
                <h3 class="primary--text text-center">Si vous avez recu un code </h3>
                <v-row>
                    <v-col cols="12" md="6" offset="0" offset-md="3">
                        <v-text-field label="Code" v-model="code"></v-text-field>
                        <v-btn color="primary" outlined style="width:100%" @click="findCode">Acceder au formulaire</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import Form from '../classes/Form';

export default {
    name: "Home",
    props : ['loggedin'],
    data() {
        return {
            code : ""
        }
    },
    methods : {
        async findCode() {
            let form = await Form.getByCode(this.code)
            if(form && form.id) {
                this.$router.push("/form/" + form.id)
            }
        }
    }
}
</script>
